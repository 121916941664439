import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Dashboard from "../../components/Dashboard"
import DashboardPengaturan from "../../components/Dashboard/Pengaturan"

const DashboardPage = () => (
  <Layout noFooter>
    <SEO title="Dashboard Pengaturan" />
    <Dashboard chosen="Pengaturan Akun" noNotif={true}>
      <DashboardPengaturan />
    </Dashboard>
  </Layout>
)

export default DashboardPage
