import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  width: 100%;
  padding: 40px 64px 40px 40px;

  background: #f7f7f7;
  border-radius: 16px;
  color: #505d68;

  font-size: 14px;

  .label-akun {
    font-size: 20px;
    font-weight: bold;
  }
`

function NotifSettingCard({ toggle, label, desc }) {
  return (
    <Styles>
      <div className="row">
        <span style={{ marginBottom: "-0.7rem", marginTop: "0.1rem" }}>
          {toggle}
        </span>
        <div className="label-akun ml-2">{label}</div>
      </div>
      <div>{desc}</div>
    </Styles>
  )
}

export default NotifSettingCard
