// Generic imports
import React, { useState, useRef } from "react"
import { connect } from "react-redux"

// Other components imports
import Button from "components/Button"
import ImageFile from "components/Reusable/Input/imagefile"
import InputToggle from "components/Reusable/Input/toggle"
import ChangePassword from "./change-password"
import { Styles } from "components/Dashboard/Pengaturan/style"
import Tabs from "components/Reusable/Tabs"
import EditSvg from "../../../images/edit.svg"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"
import NewInput from "../../Reusable/Input/NewInput"
import NotifSettingCard from "./notifSettingCard"
import NewModal from "../../Reusable/NewModal"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {
  updateUser: sessionThunks.editProfile,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const DashboardPengaturan = props => {
  const { user, updateUser } = props
  const form = useRef(null)
  const [loading, setLoading] = useState(false)
  const [pictureModal, togglePictureModal] = useState(false)
  const [newPhoto, setNewPhoto] = useState(null)
  const [currPhotoPreview, setCurrPhotoPreview] = useState(user?.photo ?? null)
  // const [changingPass, setChangePass] = useState(false)

  const submit = e => {
    e.preventDefault()
    setLoading(true)
    const data = new FormData(form.current)
    data.append("id", user.id)
    if (newPhoto) {
      data.append("photo", newPhoto)
    }
    // if (!data.get("photo") || data.get("photo").name === "") {
    //   data.delete("photo")
    // }
    updateUser(data).finally(() => {
      setLoading(false)
    })
  }

  const profileContent = (
    <>
      {pictureModal ? (
        <NewModal
          title="Foto Profil"
          onCancel={() => togglePictureModal(false)}
        >
          <ImageFile
            name="photo-profile"
            picture={currPhotoPreview}
            onPictureChange={pic => {
              setNewPhoto(pic[0])

              const reader = new FileReader()
              reader.onload = e => setCurrPhotoPreview(e.target.result)
              reader.readAsDataURL(pic[0])

              togglePictureModal(false)
            }}
          />
        </NewModal>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="left-col-inp">
          <div className="ubah-foto">
            <div
              className="picture-toggle"
              onClick={() => togglePictureModal(true)}
              style={{
                backgroundImage: `url(${currPhotoPreview})`,
                height: "120px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="edit-icon">
                <img src={EditSvg} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="right-col-inp mb-2"
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <div className="row w-100">
            <div className="col-12 col-md-6 px-0 pr-md-3">
              <NewInput
                id="first_name"
                name="first_name"
                type="text"
                placeholder="Nugi"
                label="Nama Depan"
                required
                defaultValue={user.first_name}
              />
            </div>
            <div className="col-12 col-md-6 px-0 pl-md-3">
              <NewInput
                id="last_name"
                name="last_name"
                type="text"
                placeholder="Asmara"
                label="Nama Belakang"
                required
                defaultValue={user.last_name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <NewInput
          id="email"
          name="email"
          type="email"
          placeholder="john_doe@gmail.com"
          label="Email"
          required
          defaultValue={user.email}
        />
      </div>
      <div className="row">
        <NewInput
          id="phone"
          name="phone"
          type="text"
          placeholder="817-1234-5678"
          label="Nomor HP"
          required
          defaultValue={user.phone}
        />
      </div>
      <div className="row">
        <NewInput
          id="pekerjaan"
          name="occupation"
          type="text"
          placeholder="UI/UX Designer"
          label="Pekerjaan"
          required
          defaultValue={user.occupation}
        />
      </div>
      <div className="row">
        <NewInput
          id="instansi"
          name="instansi"
          type="text"
          placeholder="Taman Siswa"
          label="Instansi"
          required
          defaultValue={user.instansi}
        />
      </div>
      <div className="d-flex mt-5 justify-content-end">
        <Button disabled={loading}>Simpan</Button>
      </div>
    </>
  )

  const AccountSettingContent = (
    <>
      <div className="row">
        <NotifSettingCard
          label="Email Notifikasi"
          toggle={
            <InputToggle
              id="email-notif"
              name="email_notification"
              icons={false}
              defaultChecked={user.email_notification}
            />
          }
          desc="Dengan mengaktifkan notifikasi email, maka kamu akan menerima email dari TamanSchool tentang kelas yang kamu ikuti, registrasi, hingga undangan untuk mengikuti kelas milik Teman. Dapatkan pernawaran terkait promo kelas dan jadwal kelas premium yang tersedia!"
        />
      </div>
      <div className="row">
        <NotifSettingCard
          label="Website Notifikasi"
          toggle={
            <InputToggle
              id="push-notif"
              name="push_notification"
              icons={false}
              defaultChecked={user.push_notification}
            />
          }
          desc="Dengan mengaktifkan notifikasi website, maka kamu akan push notification yang dapat kamu lihat melalui ikon lonceng pada website."
        />
      </div>
      <div className="row">
        <NotifSettingCard
          label="Peringatan Kelas"
          toggle={
            <InputToggle
              id="whatsapp-notif"
              name="whatsapp_notification"
              icons={false}
              defaultChecked={user.whatsapp_notification}
              disabled
            />
          }
          desc="Dengan mengaktifkan notifikasi whatsap, maka kamu akan menerima whatsapp dari TamanSchool tentang kelas yang kamu."
        />
      </div>
      <div className="d-flex mt-5 justify-content-end">
        <Button disabled={loading}>Simpan</Button>
      </div>
    </>
  )

  const tabsData = [
    {
      label: "Profil",
      content: profileContent,
    },
    {
      label: "Pengaturan Akun",
      content: AccountSettingContent,
    },
    {
      label: "Password",
      content: <ChangePassword />,
    },
  ]

  return (
    <Styles className="login-register">
      <form ref={form} onSubmit={submit}>
        <h2 className="mb-3">Profile and Settings</h2>
        <Tabs tabs={tabsData} />
      </form>
    </Styles>
  )
}

export default withConnect(DashboardPengaturan)
