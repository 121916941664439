import styled from "styled-components"

export const Styles = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(33px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;

  .box {
    background: white;
    border-radius: 16px;
    padding: 42px 52px 64px 52px;
  }
`
