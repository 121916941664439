import React, { useState } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"

import Button from "components/Button"

import { setPassword } from "services/auth"
import { handleAJAX } from "services/utils"
import NewInput from "../../Reusable/Input/NewInput"

const Styles = styled.div`
  /* padding-top: 12px; */

  button {
    margin: 6px;
  }
`

export default function ChangePassword({ setChangePass }) {
  const [currentPass, setCurrentPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [reNewPass, setReNewPass] = useState("")
  const [loadingPass, setLoadingPass] = useState(false)

  return (
    <Styles>
      <div className="row">
        <NewInput
          id="current_password"
          name="current_password"
          type="password"
          value={currentPass}
          required
          label="Current Password"
          onChange={e => setCurrentPass(e.target.value)}
        />
      </div>
      <div className="row">
        <NewInput
          id="new_password"
          name="new_password"
          type="password"
          value={newPass}
          required
          label="New Password"
          onChange={e => setNewPass(e.target.value)}
        />
      </div>
      <div className="row">
        <NewInput
          id="re_new_password"
          name="re_new_password"
          type="password"
          value={reNewPass}
          required
          label="Repeat New Password"
          onChange={e => setReNewPass(e.target.value)}
        />
      </div>
      <div className="d-flex mt-5 justify-content-end">
        {/* <Button
          className="secondary"
          type="button"
          onClick={() => {
            setChangePass(false)
          }}
        >
          Cancel
        </Button> */}
        <Button
          type="button"
          onClick={async () => {
            setLoadingPass(true)
            await handleAJAX(
              () => {
                return setPassword({
                  current_password: currentPass,
                  new_password: newPass,
                  re_new_password: reNewPass,
                })
              },
              () => {
                toast.success("Password successfully changed")
              }
            )
            setLoadingPass(false)
          }}
          disabled={loadingPass}
        >
          Simpan
        </Button>
      </div>
    </Styles>
  )
}
