import React from "react"
import { Styles } from "./style"

import CloseSvg from "../../../images/close.svg"

function NewModal({ onCancel, title, children }) {
  return (
    <Styles>
      <div className="box">
        <div className="mb-5">
          <a onClick={onCancel} style={{ marginRight: "16px" }}>
            <img src={CloseSvg} />
          </a>
          <span>{title}</span>
        </div>
        <div>{children}</div>
      </div>
    </Styles>
  )
}

export default NewModal
