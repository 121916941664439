import React from "react"
import styled from "styled-components"
import Input from "."

const Styles = styled.div`
  width: 100%;

  label {
    font-weight: bold;
  }

  input {
    padding: 10.5px 12px;

    border: 0;
    border-radius: 6px;
    background: #f7f7f7;
    color: #666666;
  }
`

function NewInput(props) {
  const { label, id, ...otherProps } = props
  const inputId = id !== null ? id : props.name

  return (
    <Styles>
      <Input label={label} id={inputId} {...otherProps} />
    </Styles>
  )
}

export default NewInput
