import styled from "styled-components"

export const Styles = styled.div`
  max-width: 1024px;
  margin: auto;

  .fileContainer {
    padding: 0;
    margin: 0;
    width: 270px;
    height: 270px;
    background: transparent;
    box-shadow: none;
    border: 1px dashed #a0a4a8;
  }

  .chooseFileButton {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: 0;
    background: white;
    color: black;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .has-profile-pic img {
    opacity: 0;
    transition: 0.2s opacity;
  }

  .chooseFileButton:hover {
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: white;
    opacity: 0.8;

    .has-profile-pic img {
      opacity: 1;
    }
  }

  .uploadIcon,
  .fileContainer p {
    display: none;
  }

  .fileContainer {
    width: 320px;
    height: 320px;
  }

  .left-col-inp {
    width: 120px;

    .ubah-foto {
      margin-top: -12px;
    }

    label {
      padding-top: 15px;
    }

    .picture-toggle {
      border-radius: 4px;
      padding: 8px;

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      :hover {
        cursor: pointer;
      }

      .edit-icon {
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 7px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  h2 {
    margin-top: 1.5rem;
    font-family: "Source Serif Pro", serif;
    font-weight: 700;
  }

  .row {
    padding-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  .right-col-inp {
    width: calc(100% - 120px);
    padding-left: 1rem;
    /* padding-right: 1rem; */
  }

  .notifikasi-left-col {
    width: calc(100% - 60px);
  }

  .notifikasi-right-col {
    padding-left: 10px;
    width: 60px;
  }

  .taman-input,
  .taman-select {
    margin-bottom: 0.7rem;

    label {
      color: black;
    }
  }

  .simpan-button {
    padding-top: 15px;

    button {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .row {
    }

    .left-col-inp {
      width: 100%;

      .ubah-foto {
        padding-bottom: 20px;
      }

      label {
        padding-top: 0;
      }
    }

    .right-col-inp {
      width: 100%;
      padding: 0;
    }
  }
`
